import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import AuthContext from '../../contexts/authContext';

import styles from './SubMenu.module.scss';
import { Typography } from '@mui/material';

const SubMenu = () => {
  const { menuAdItem } = useContext(AuthContext);
  const subMenuItems = menuAdItem.find((item) =>
    window.location.pathname.includes(item.name)
  ).children;

  return (
    <div className={styles['sub-menu']}>
      {subMenuItems.map((item, index) => (
        <NavLink
          key={index}
          to={item.url}
          className={({ isActive }) => {
            const activeCls = isActive ? 'active' : '';

            return `${styles['sub-menu-item']} ${styles[activeCls]}`;
          }}
        >
          <Typography variant="body2" component="span">
            {' '}
            {item.title}
          </Typography>
        </NavLink>
      ))}
    </div>
  );
};

export default SubMenu;
