import React from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Grid, Button, Typography } from '@mui/material';
import { domain } from '../../api/httpClient';
import { addDomain } from '../../store/domainsReducer';
import { notificationSuccess } from '../../store/notificationReducer';
import useValidateDomain from '../../hooks/useValidateDomain';
import TextField from '../text-field/TextField';
import './Domains.scss';

const CreateDomain = () => {
  const dispatch = useDispatch();
  const isAuthData = useSelector((state) => state.auth.auth);

  const {
    name,
    nameIsBlur,
    nameError,
    formIsValid,
    formIsSuccess,
    domainData,
    nameHandler,
    blurHandler,
    setName,
    setNameError,
    setFormIsValid,
    setFormIsSuccess,
  } = useValidateDomain();

  //add new domain
  const notificationCreate = {
    isNotification: true,
    type: 'success',
    action: 'create_domain',
    message: `${name} was successfully created`,
  };

  const onCreateDomain = (data) => {
    axios({
      method: 'POST',
      url: `${domain}/ad/rest_api/v1/users/${isAuthData.user_id}/agent_domains/`,
      withCredentials: true,
      data: data,
    })
      .then((response) => {
        dispatch(addDomain(response.data));
        dispatch(notificationSuccess(notificationCreate));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formSend = (e) => {
    e.preventDefault();

    setFormIsSuccess(!formIsSuccess);
    setFormIsValid(false);

    onCreateDomain(domainData);

    setName('');
    setNameError(' ');
  };

  return (
    <Stack component="form">
      <Typography variant="subtitle1" component="h2" sx={{ fontWeight: 500 }}>
        Create Domain
      </Typography>
      <Stack
        direction={{ md: 'row' }}
        alignItems={{ md: 'flex-end' }}
        justifyContent={{ md: 'space-between' }}
        gap={3}
        pt={{ xs: 2, md: 0 }}
        className="domains-add-form-controls"
      >
        <Grid item xs={12} md={7}>
          <TextField
            name="domain"
            label="Name"
            value={name}
            onChange={nameHandler}
            onBlur={blurHandler}
          />
          {nameIsBlur && nameError && (
            <span className="validate-error">{nameError}</span>
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          <Button
            type="submit"
            variant="contained"
            disabled={formIsValid ? false : true}
            size="large"
            onClick={formSend}
          >
            Create
          </Button>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default CreateDomain;
