import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Grid, Button, Typography } from '@mui/material';
import { domain } from '../../api/httpClient';
import { createAuthKey } from '../../store/usersReducer';
import Tooltip from '../tooltip/Tooltip';
import './Domains.scss';

const ConfigurateAd = () => {
  const dispatch = useDispatch();
  const isAuthData = useSelector((state) => state.auth.auth);
  const isAuthKeyAd = useSelector((state) => state.users.auth_key);
  const domainsList = useSelector((state) => state.domains.domains_list);

  useEffect(() => {
    if (domainsList) {
      if (!isAuthKeyAd.length && domainsList.length >= 1) {
        onAuthKeyCreate();
      }
    }
  }, [domainsList]);

  const onAuthKeyCreate = () => {
    axios({
      method: 'POST',
      url: `${domain}/ad/rest_api/v1/users/${isAuthData.user_id}/ad_auth_key/`,
      withCredentials: true,
      data: {
        user_id: isAuthData.user_id,
      },
    })
      .then((response) => {
        dispatch(createAuthKey(response.data));
      })
      .catch((error) => {
        console.log(error.response.data.detail);
      });
  };

  return (
    <div className="domains-configurate-ad">
      <div className="domains-configurate-ad-title">
        <Typography variant="subtitle1" component="h2" sx={{ fontWeight: 500 }}>
          Configurate Active Directory
        </Typography>

        <p>
          Follow the instructions to configure Active Directory and download the
          Agent
        </p>
      </div>
      <Stack
        gap={1}
        direction={{ md: 'row' }}
        className="domains-add-form-controls"
        pt={{ xs: 3 }}
      >
        <Grid item xs={12} md={6}>
          <Tooltip
            title={
              <React.Fragment>
                <h3>Key generation.</h3>
                <br />
                <p>
                  The Agent will be available for download after the key
                  generation is completed
                </p>
              </React.Fragment>
            }
          >
            <span>
              <Button
                variant="contained"
                size="large"
                disabled={isAuthKeyAd.length ? false : true}
              >
                Download the Agent
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="outlined"
            size="large"
            href="https://docs.safedns.com/books/active-directory-agent-installation/page/setting-up-customer-in-the-activedirectory-section"
            target="_blank"
          >
            Instruction
          </Button>
        </Grid>
      </Stack>
    </div>
  );
};

export default ConfigurateAd;
