import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Stack, Grid, Button, Typography, Box } from '@mui/material';
import ModalContext from '../../contexts/modal-context';
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';

import { deleteDomain } from '../../asyncActions/deleteDomain';

const RemoveDomain = () => {
  const dispatch = useDispatch();
  const { user_id } = useSelector((state) => state.auth.auth);
  const domainData = useSelector((state) => state.domains.domain_id);
  const { isShowRemoveModal, setIsShowRemoveModal } = useContext(ModalContext);

  const notificationData = {
    isNotification: true,
    type: 'success',
    action: 'remove_domain',
    message: `${domainData.domain_name} was successfully deleted`,
  };

  const onToggleModal = () => {
    return setIsShowRemoveModal(!isShowRemoveModal);
  };

  const deleteDomainHandler = () => {
    dispatch(deleteDomain(user_id, domainData.id, notificationData));
    setIsShowRemoveModal(!isShowRemoveModal);
  };

  return (
    <Dialog open={isShowRemoveModal}>
      <Stack sx={{ gap: '12px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '48px',
            height: '48px',
            margin: 'auto',
            borderRadius: '50%',
            backgroundColor: 'warning.main',
          }}
        >
          <GppBadOutlinedIcon
            fontSize="medium"
            sx={{ color: 'primary.contrastText' }}
          />
        </Box>
        <Typography variant="h6">
          Are you sure you want to delete <br /> the&nbsp;
          {domainData.domain_name}?
        </Typography>
        <Typography component="p">
          Users and collections that are associated with this domain will also
          be deleted
        </Typography>
        <Grid container sx={{ pt: '12px', gap: 3 }}>
          <Button
            variant="error"
            size="small"
            onClick={deleteDomainHandler}
            sx={{
              flex: '1 0',
              backgroundColor: 'error.main',
              color: '#fff',
              '&:hover': { backgroundColor: 'error.main' },
            }}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            onClick={onToggleModal}
            sx={{ flex: '1 0' }}
          >
            Cancel
          </Button>
        </Grid>
      </Stack>
    </Dialog>
  );
};

export default RemoveDomain;
