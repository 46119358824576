import React from 'react';
import { useSelector } from 'react-redux';

import styles from './Header.module.scss';
import { Typography } from '@mui/material';

const Header = () => {
  const isAuthData = useSelector((state) => state.auth.auth);

  return (
    <header className={styles['header']}>
      <div className={styles['header-controls']}>
        {isAuthData.permissions.is_staff && (
          <a
            href="https://www.safedns.com/you-were-never-here/"
            className={styles['header-controls-dashboard']}
          >
            {' '}
          </a>
        )}
        <Typography variant="h1" component="span">
          {isAuthData.username}
        </Typography>
        {isAuthData.is_authenticated && (
          <a
            href="https://www.safedns.com/auth/logout/"
            className={styles['header-controls-logout']}
          >
            {' '}
          </a>
        )}
      </div>
    </header>
  );
};

export default Header;
