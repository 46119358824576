import { api, domain } from '../api/httpClient';

import { removeDomain } from '../store/domainsReducer';
import { notificationSuccess } from '../store/notificationReducer';

export function deleteDomain(userId, domainId, domainData) {
  return function (dispatch) {
    api
      .delete(
        `${domain}/ad/rest_api/v1/users/${userId}/agent_domains/${domainId}/`
      )
      .then(() => {
        dispatch(removeDomain(domainId));
        dispatch(notificationSuccess(domainData));
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
