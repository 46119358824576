import { api, domain } from '../api/httpClient';

import { removeCollection } from '../store/collectionsReducer';
import { notificationSuccess } from '../store/notificationReducer';

export function deleteCollection(userId, collectionId, collectionData) {
  return function (dispatch) {
    api
      .delete(
        `${domain}/ad/rest_api/v1/users/${userId}/collections/${collectionId}/`
      )
      .then(() => {
        dispatch(removeCollection(collectionId));
        dispatch(notificationSuccess(collectionData));
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
